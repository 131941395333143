.App {
    display: flex;
    flex-direction: column;
}

.app-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.fade-in {
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
}

.fade-out {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    pointer-events: none;
}

.sticky {
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: white;
}

.section-title {
    width: 100%;
    padding: 10px 0px;
    text-align: left;
}

.view-paper {
    flex: 1;
    margin: 1% 5% 0% 5%;
}

.name-title {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.title-hr {
    width: 100%;
}

p {
    font-size: 1.2rem;
}

.footer {
    margin-top: 20px;
    color: black;
    padding: 5px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

hr {
    border-color: black;
    border-width: 0.5px;
}
